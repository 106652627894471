import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'

const LandingPageContainer = React.lazy(() =>
  import(
    /* webpackChunkName: 'plan-entry-proxy' */
    'components_v2/MCOptionalEligibility/landing-page/landing-page-container'
  )
)

export const MCOptionalEligibilityLandingTempEntry = ({ user }) => {
  return (
    <TempEntry
      deprecated
      mt={['0px', null, '0px']}
      pb={['30px']}
      user={user}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback={<></>}>
        <LandingPageContainer />
      </Suspense>
    </TempEntry>
  )
}

MCOptionalEligibilityLandingTempEntry.propTypes = {}

export default MCOptionalEligibilityLandingTempEntry
